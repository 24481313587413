// Generated by Framer (78454af)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["iso4bs4NC", "EMsMPJdYm"];

const serializationHash = "framer-ckdyP"

const variantClassNames = {EMsMPJdYm: "framer-v-10fjefo", iso4bs4NC: "framer-v-8xc9qp"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 2": "EMsMPJdYm", Image: "iso4bs4NC"}

const getProps = ({appear, height, id, screen1, width, ...props}) => { return {...props, mC4Wd_Vhb: screen1 ?? props.mC4Wd_Vhb ?? {src: "https://framerusercontent.com/images/xGnj3aLI60sAwnRnVYydW6NHBeo.jpg?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/xGnj3aLI60sAwnRnVYydW6NHBeo.jpg?scale-down-to=1024 576w,https://framerusercontent.com/images/xGnj3aLI60sAwnRnVYydW6NHBeo.jpg?scale-down-to=2048 1152w,https://framerusercontent.com/images/xGnj3aLI60sAwnRnVYydW6NHBeo.jpg 2304w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "iso4bs4NC", YVksAO26v: appear ?? props.YVksAO26v} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;screen1?: {src: string; srcSet?: string};appear?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, mC4Wd_Vhb, YVksAO26v, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "iso4bs4NC", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppearnewbye = activeVariantCallback(async (...args) => {
if (YVksAO26v) {const res = await YVksAO26v(...args);
if (res === false) return false;}
await delay(() => setVariant(CycleVariantState), 2000)
})

useOnVariantChange(baseVariant, {default: onAppearnewbye})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", pixelHeight: 1456, pixelWidth: 816, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(mC4Wd_Vhb)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-8xc9qp", className, classNames)} data-framer-name={"Image"} data-highlight layoutDependency={layoutDependency} layoutId={"iso4bs4NC"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} {...addPropertyOverrides({EMsMPJdYm: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-ckdyP.framer-13jgd7y, .framer-ckdyP .framer-13jgd7y { display: block; }", ".framer-ckdyP.framer-8xc9qp { height: 724px; overflow: hidden; position: relative; width: 334px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 724
 * @framerIntrinsicWidth 334
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"EMsMPJdYm":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"mC4Wd_Vhb":"screen1","YVksAO26v":"appear"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZjH_iAr0p: React.ComponentType<Props> = withCSS(Component, css, "framer-ckdyP") as typeof Component;
export default FramerZjH_iAr0p;

FramerZjH_iAr0p.displayName = "Screen Content Copy";

FramerZjH_iAr0p.defaultProps = {height: 724, width: 334};

addPropertyControls(FramerZjH_iAr0p, {variant: {options: ["iso4bs4NC", "EMsMPJdYm"], optionTitles: ["Image", "Variant 2"], title: "Variant", type: ControlType.Enum}, mC4Wd_Vhb: {__defaultAssetReference: "data:framer/asset-reference,xGnj3aLI60sAwnRnVYydW6NHBeo.jpg?originalFilename=instagram-extended.jpg&preferredSize=auto", title: "Screen1", type: ControlType.ResponsiveImage}, YVksAO26v: {title: "Appear", type: ControlType.EventHandler}} as any)

addFonts(FramerZjH_iAr0p, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})